/* All entries alphabetical, except: body, *, theme at bottom */

.centered {
  text-align: center;
}

.card-height {
  min-height: 350px;
  margin: 10px;
}

.company-logo-name-container{
  display: inline-block;
  padding-left: 10px;
}

.corner-sharp {
  border-radius: 1px !important;
}

.fix-sidebar {
  transform: none !important;
}

.font-heading {
  font-family: 'Josefin Sans', sans-serif;
}

.font-size-small {
  font-size: 1rem;
  line-height: 1.25rem;
}

.font-size-medium {
  font-size: 1.5rem;
  line-height: 2rem;
}

.font-size-large {
  font-size: 2rem;
  line-height: 4rem;
}

.font-size-huge {
  font-size: 3rem;
  line-height: 4rem;
}

.heading-outer {
  position:relative;
  width:100%;
}

.font-heading {
  font-family: 'Josefin Sans', sans-serif;
}

.image-circle-portrait {
  border-radius: 50%;
  object-fit: cover;
  min-height: 250px;
  max-height: 500px;
}

.image-circle-small-skill {
  min-width: 70px !important;
  max-width: 80px !important;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid rgba(0, 129, 129, .3);
}

.image-circle-small-skill img {
  position: absolute;
  left: -1000%;
  right: -1000%;
  top: -1000%;
  bottom: -1000%;
  margin: auto;
  min-height: 100%;
  min-width: 100%;
}

img {
  height: auto;
  max-width: 100%;
}

.left {
  text-align: left;
}

.text {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 2rem;
  text-align: justify;
}

/* ----------------- THEMES ------------------- */

.theme-default-heading{
  background: linear-gradient(90deg, rgba(255, 0, 191, 0.25) 0%, rgba(255,255,255,0) 100%);
  }

.theme-default:before {
  content: '';
  position: fixed;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background: rgb(0,191,255);
  background: linear-gradient(0deg, rgba(0,191,255,0.2) 0%, rgba(255,255,255,1) 100%);
  background-image: url(/images/light_noise_diagonal_@2X.png) green;
  opacity: 0.4;
}

.theme-default:after {
  content: '';
  position: fixed;
  z-index: -1;
  backface-visibility: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: grayscale(10%) invert(10%);
  background: rgb(0,191,255);
  background: linear-gradient(0deg, rgba(0,191,255,0.2) 0%, rgba(255,255,255,1) 100%);
}

.theme-autumn-heading{
  background: linear-gradient(90deg, rgba(60,97,106, 1) 0%, rgba(255,255,255,0) 100%);
  }

.theme-autumn:before {
  content: '';
  position: fixed;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background: rgb(160,41,17);
  background: linear-gradient(0deg, rgba(160,41,17,1) 0%, rgba(255,255,255,1) 100%);
  opacity: 0.4;
}

.theme-autumn:after {
  content: '';
  position: fixed;
  z-index: -1;
  backface-visibility: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: grayscale(10%) invert(10%);
  background: rgb(160,41,17);
  background: linear-gradient(0deg, rgba(160,41,17,0.2) 0%, rgba(255,255,255,1) 100%);
}

.theme-ice-heading{
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255,255,255,0) 100%);
  }

.theme-ice:before {
  content: '';
  position: fixed;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background: rgb(25,149,173);
  background: linear-gradient(0deg, rgba(25,149,173,0.2) 0%, rgba(255,255,255,1) 100%);
  opacity: 0.4;
}

.theme-ice:after {
  content: '';
  position: fixed;
  z-index: -1;
  backface-visibility: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: grayscale(10%) invert(10%);
  background: rgb(25,149,173);
  background: linear-gradient(0deg, rgba(25,149,173,0.2) 0%, rgba(255,255,255,1) 100%);
}


.theme-berries-heading{
  background: linear-gradient(90deg, rgba(93, 85, 94, 1) 0%, rgba(255,255,255,0) 100%);
  }

.theme-berries:before {
  content: '';
  position: fixed;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background: rgb(223,225,102);
  background: linear-gradient(0deg, rgba(223,225,102,1) 0%, rgba(255,255,255,1) 100%);
  opacity: 1;
}

.theme-berries:after {
  content: '';
  position: fixed;
  z-index: -1;
  backface-visibility: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: grayscale(10%) invert(10%);
  background: rgb(223,225,102);
  background: linear-gradient(0deg, rgba(223,225,102,1) 0%, rgba(255,255,255,1) 100%);
}
